<template>
  <div>
    <h3>Påstand:</h3>
  <h2 class="paastander">Uia bør anerkjenne mer enn to kjønn.</h2> <!-- Expression/Question -->
    <vsa-list>
      <vsa-item>
        <vsa-heading>
          Les mer her
        </vsa-heading>

        <vsa-content>
          <div class="explaination">
            <p>
              UiA mener de ikke skal anerkjenner flere enn to kjønn ettersom norsk lov ikke gjør det,
              men nå har UiB gått videre og gjort det.
              Spørsmålet er da om vi skal anerkjenne mer enn to kjønn?
            </p>
          </div>
        </vsa-content>
      </vsa-item>
    </vsa-list>
    <slot>></slot> <!-- Slot for react buttons -->
  </div>
</template>

<script>
import {
  VsaList,
  VsaItem,
  VsaHeading,
  VsaContent,
} from 'vue-simple-accordion';
import 'vue-simple-accordion/dist/vue-simple-accordion.css';

export default {
  name: "spm2",
  components:{
    VsaList,
    VsaItem,
    VsaHeading,
    VsaContent,
  }
}
</script>

<style scoped>

</style>